import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrcaAPI } from '../../ApiServices/OrcaAPI';
import { React } from 'react';

const initialState = {
  tableData: [],
};

export const brndindSlice = createSlice({
  name: 'brandindication',
  initialState,
  reducers: {
    setbrandindicationData: (state, action) => {
        state.tableData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getbrandindicationData.pending, (state) => {
        state.tableData = [];
        state.loading = true;
      })
      .addCase(getbrandindicationData.fulfilled, (state, action) => {
        //console.log("Fulfilled Payload:", action.payload);
        state.loading = false;
        let tableData = action.payload;
        state.tableData = tableData.map(row => {
          let newrow = {
            ...row,
          }
          return newrow;
        });
        state.tableData = state.tableData.sort((a, b) => { return Number(b.id) - Number(a.id)})
        //console.log(state.tableData)
      })
      .addCase(getbrandindicationData.rejected, (state) => {
        state.loading = false;
        state.tableData = [];
      }).addCase(insertbrandindicationData.pending, (state) => {
        state.loading = true;
      }).addCase(insertbrandindicationData.fulfilled, (state, action) => {
        state.loading = false;
        state.tableData = state.tableData.filter((row) => { return row.id !== action.payload.id; });
      }).addCase(insertbrandindicationData.rejected, (state) => {
        state.loading = false;
      }).addCase(updatebrandindicationData.pending, (state) => {
        state.loading = true;
      }).addCase(updatebrandindicationData.fulfilled, (state, action) => {
        state.loading = false;
        state.tableData = state.tableData.map((row) => {
          if (row.id === action.payload.id) {
            return action.payload;
          }
          return row;
        });
      }).addCase(updatebrandindicationData.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const getbrandindicationData = createAsyncThunk('requests/getbrandindicationData', async () => {
  const response = await OrcaAPI({ uri: "/brandindication", method: "GET" });
  return response.data;
});

export const insertbrandindicationData = createAsyncThunk('requests/insertbrandindicationData', async (data) => {
  data = { ...data, action: 'insert' };
  const response = await OrcaAPI({ uri: "/brandindication", method: "POST", body: data });
  if (response.statusCode === 200) {
    return data;
  }
  return null;
});

export const updatebrandindicationData = createAsyncThunk('requests/updatebrandindicationData', async (data) => {
  data = { ...data, action: 'save'}
  const response = await OrcaAPI({ uri: "/brandindication", method: "PUT", body: data });
  if (response.statusCode === 200) {
    return data;
  }
  return null;
});

export const { setbrandindicationData } = brndindSlice.actions;
export const selectbrandindicationData = (state) => state.brndindSlice.tableData;
export const getLoadingState = (state) => state.brndindSlice.loading;

export default brndindSlice;
